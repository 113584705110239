<template>
	<div class="login_box">
		<img src="https://xicheapi.keansafe.com/account/img/woyaoyou_bg_login@2x.fafb2e1b.png" class="login-box-cover-img">

        <div class="main_box">
            <div class="item">
                <div class="text">
                    账号
                </div>
                <div class="item_input">
                    <a-input placeholder="请输入账号" v-model="form.mobile" />
                </div>
            </div>
            <div class="item">
                <div class="text">
                    密码
                </div>
                <div class="item_input">
                    <a-input placeholder="请输入密码" type="password" v-model="form.pwd" />
                </div>
            </div>
            <a-button type="primary" @click="takeLogin">
                登录
            </a-button>
        </div>
	</div>
</template>

<script>
	import uploadList from '@/components/upload/list.vue';
	export default{
		components:{
			uploadList,
		},
		data(){
			return {
				loading:false,
				photos: [],
                datas: [],
                form: {
                    mobile: '',
                    pwd: ''
                },
			}
		},
        created(){
            let token = localStorage.getItem('auto-token');
            if(token){
				this.$router.push('/index');
            }
        },
		methods:{
			takeLogin(){
                this.$http.papi('platform/account/pwdLogin',this.form).then(res=>{
                    let shop_token = '3df7/OcNSP4PE2kGUJCaIg5oVpfLq0mwD/njxMrupt87kdU1DpDXaITEZFMg/KxDD7ya+vHBnv9EwMM3rmyh7oF2n0mcPA'
                    localStorage.setItem('auto-token',shop_token);
					this.$router.push('/index');
				}).catch(res=>{
					
				});	
            },
        }
	}
</script>

<style scoped>
	.login_box{
		width: 100vw;
        height: 100vh;
        background: linear-gradient(135deg,#4772ff,#5851ff);
        position: relative;
	}
    .login-box-cover-img{
        position: absolute;
        bottom: 0;
        width: 100vw;
    }
    .main_box{
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 400px;
        height: 240px;
        background: #fff;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
    .item{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .text{
        width: 30px;
        text-align: right;
    }
    .item_input{
        border: none;
        width: 240px;
        margin-left: 10px;
        height: 25px;
    }
</style>